// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-layouts-keyword-page-layout-js": () => import("./../../../src/layouts/keyword-page-layout.js" /* webpackChunkName: "component---src-layouts-keyword-page-layout-js" */),
  "component---src-layouts-pagination-layout-js": () => import("./../../../src/layouts/pagination-layout.js" /* webpackChunkName: "component---src-layouts-pagination-layout-js" */),
  "component---src-layouts-post-layout-js": () => import("./../../../src/layouts/post-layout.js" /* webpackChunkName: "component---src-layouts-post-layout-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-keywords-js": () => import("./../../../src/pages/keywords.js" /* webpackChunkName: "component---src-pages-keywords-js" */)
}

